import React from "react";
import { useTranslation } from "react-i18next";

import WidgetLoadingSpin from "./WidgetLoadingSpin";
import { PAGE_LAYOUT_WIDGET_PARAMETER } from "../Constants.js";
import { PAGE_PERIOD_LENGEND_TEXT } from "../../../insightsPage/common";
import "./ChartCommon.scss";
import { Utils } from "../../../../Utils";
import { ACL_ROLE } from "../../../../Constants";
import { useUser } from "../../../../components/userContext/UserContext";

const BarChartWithMeterUsageTypeWidgetLazy = React.lazy(() => import("./BarChartWithMeterUsageTypeWidget"));
const BarChartWithMeterConsumptionCompareWidgetLazy = React.lazy(() => import("./BarChartWithMeterConsumptionCompareWidget"));
const BarChartMeterBreakDownWidgetLazy = React.lazy(() => import("./BarChartMeterBreakDownWidget"));
const BarChartHorizontalWithAirQualityWidgetLazy = React.lazy(() => import("./BarChartHorizontalWithAirQualityWidget"));
const ScatterChartTemperatureDistributionWidgetLazy = React.lazy(() => import("./ScatterChartWithTemperatureDistributionWidget"));
const MeterConsumptionPercentDifferWidgetLazy = React.lazy(() => import("./MeterConsumptionPercentDifferWidget"));
const LineChartWidgetLazy = React.lazy(() => import("./LineChartWithGreenWidget"));
const PieChartConsumptionWidgetLazy = React.lazy(() => import("./PieChartConsumptionWidget"));
const GreenScoreWidgetLazy = React.lazy(() => import("./GreenScroeWidget"));
const HeatMapChartWidgetLazy = React.lazy(() => import("./HeatMapChartWidget"));
const BarAndLineChartWithACTemperatureWidgetLazy = React.lazy(() => import("./BarAndLineChartWithACTemperatureWidget"));
const BarAndLineChartWithLightingOperateWidgetLazy = React.lazy(() => import("./BarAndLineChartWithLightingOperateHourWidget"));
const BarAndLineChartWithComparisonWidgetLazy = React.lazy(() => import("./BarAndLineChartWithComparisonWidget"));
const AcThermalRequestSummaryWidgetLazy = React.lazy(() => import("./AcThermalRequestSummaryWidget"));
const AcThermalRequestCompareWidgetLazy = React.lazy(() => import("./AcThermalRequestCompareWidget"));
const WeatherWidgetLazy = React.lazy(() => import("./WeatherWidget"));

const noHeaderWidget = [
  "ScoreCardWidget",
  "BarAndLineChartWithACTemperatureWidget",
  "BarChartHorizontalWithAirQualityWidget",
  "BarAndLineChartWithLightingOperateHourWidget",
  "ACRequestSummaryWidget",
  "ACRequestCompareWidget",
  "WeatherWidget"
];

export default function WidgetInitCreate({ widget, params, domToCanvasExport, setDraggable }) {
  const { t } = useTranslation("common");
  const { userRes } = useUser();
  const handleEditWidget = (e, widget) => {
    e.stopPropagation();
    window.parent.postMessage({
      type: "redirect",
      action: `/main/head/insights?id=${widget?.widgetId}`
    });
  };

  const handleMoveWidget = () => {
    window.$("body").addClass("react-draggable-transparent-selection");
    setDraggable(true);
  };
  const handleUnMoveWidget = () => {
    window.$("body").removeClass("react-draggable-transparent-selection");
    setDraggable(false);
  };

  const handleExportWidget = (e, widget) => {
    e.stopPropagation();
    domToCanvasExport(widget.id);
  };
  const handleWidgetComponent = widget => {
    if (widget.widgetName === "HeatMapWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <HeatMapChartWidgetLazy widget={widget} params={params} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "LineChartWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <LineChartWidgetLazy widget={widget} params={params} />
        </React.Suspense>
      );
    } else if (widget.visualType === "PieChartConsumptionWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <PieChartConsumptionWidgetLazy widget={widget} params={params} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarChartWaterBreakDownWidget" || widget.widgetName === "BarChartEnergyBreakDownWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarChartMeterBreakDownWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarChartWithMeterConsumptionCompareWidget" || widget.widgetName === "BarChartWithBDSTWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarChartWithMeterConsumptionCompareWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarChartWithMeterUsageTypeWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarChartWithMeterUsageTypeWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarChartWithMeterConsumptionPercentDifferWidget" || widget.visualType === "BarChartWithConsumptionRankingWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <MeterConsumptionPercentDifferWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "TemperatureWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <ScatterChartTemperatureDistributionWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "ScoreCardWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <GreenScoreWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarChartHorizontalWithStackedWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarChartHorizontalWithAirQualityWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarAndLineChartWithACTemperatureWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarAndLineChartWithACTemperatureWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarAndLineChartWithLightingOperateHourWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarAndLineChartWithLightingOperateWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "ACRequestSummaryWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <AcThermalRequestSummaryWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "ACRequestCompareWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <AcThermalRequestCompareWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "WeatherWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <WeatherWidgetLazy widget={widget} />
        </React.Suspense>
      );
    } else if (widget.widgetName === "BarAndLineChartWithComparisonWidget") {
      return (
        <React.Suspense fallback={<WidgetLoadingSpin />}>
          <BarAndLineChartWithComparisonWidgetLazy widget={widget} />
        </React.Suspense>
      );
    }
  };
  const handleDisplayIconByType = (type, visualType, unit) => {
    if (type === PAGE_LAYOUT_WIDGET_PARAMETER.WIDGET_SOURCE_TYPE["WET_ELECTRICITY"]) {
      if (
        visualType == "WeatherWidget" ||
        visualType == "ScoreCardWidget" ||
        visualType == "BarAndLineChartWithACTemperatureWidget" ||
        visualType == "BarAndLineChartWithLightingOperateHourWidget" ||
        visualType == "ACRequestSummaryWidget" ||
        visualType == "ACRequestCompareWidget"
      ) {
        return null;
      }
      if (visualType == "BarChartHorizontalWithStackedWidget") {
        return <div className="icon iaqBlue p-3"></div>;
      }
      if (unit.indexOf("CO2") != -1) {
        return <div className="icon dashboard widgetCo2"></div>;
      }
      return <div className="icon dashboard widgetConflict"></div>;
    } else if (type === PAGE_LAYOUT_WIDGET_PARAMETER.WIDGET_SOURCE_TYPE["WET_WATER"]) {
      if (
        visualType == "WeatherWidget" ||
        visualType == "ScoreCardWidget" ||
        visualType == "BarAndLineChartWithACTemperatureWidget" ||
        visualType == "BarAndLineChartWithLightingOperateHourWidget" ||
        visualType == "ACRequestSummaryWidget"
      ) {
        return null;
      }
      if (visualType == "BarChartHorizontalWithStackedWidget") {
        return <div className="icon iaq p-3"></div>;
      }
      return <div className="icon dashboard widgetWater"></div>;
    } else {
      return <div className="icon dashboard widgetTemperature"></div>;
    }
  };
  let description = "",
    default_period = undefined;
  let parameters = JSON.parse(widget.parameters);
  if (widget?.params?.period) {
    default_period = widget?.params?.period;
  } else if (parameters.default_period) {
    default_period = parameters?.default_period;
  }

  if (widget?.description?.length) {
    description = widget?.description + (default_period ? "." + PAGE_PERIOD_LENGEND_TEXT(default_period, t) : "");
  } else {
    description = default_period ? PAGE_PERIOD_LENGEND_TEXT(default_period, t) : "-";
  }
  return (
    <>
      <div className={`dashboard-widget-header ${noHeaderWidget.includes(widget.visualType) ? null : "mb-25"}`}>
        <div className="header-title-item">
          {handleDisplayIconByType(widget.type, widget.visualType, widget.unit)}
          {widget.visualType == "WeatherWidget" ? null : widget.visualType == "ScoreCardWidget" ? (
            <div className="heading-wrapper mx-0">
              <span className="mainHeading big" title={widget?.name || "-"}>
                {widget?.name || "-"}
              </span>
            </div>
          ) : noHeaderWidget.includes(widget.visualType) ? null : (
            <div className="heading-wrapper">
              <div className="mainHeading small" title={widget?.name || "-"}>
                {widget?.name || "-"}
              </div>
              <div className="subHeading smaller" title={description || "-"}>
                {widget?.description?.length ? (
                  <div className={"sub-box"}>
                    <span className={"title"}>{widget?.description}</span>
                    {default_period ? <span>{"." + PAGE_PERIOD_LENGEND_TEXT(default_period, t)}</span> : null}
                  </div>
                ) : (
                  <div>{default_period ? PAGE_PERIOD_LENGEND_TEXT(default_period, t) : "-"}</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="header-title-item">
          {Utils.isAclRole(userRes.data, ACL_ROLE.dashboardAdmin) ? <div className="icon dashboard widgetEdit editIconSize" onClick={e => handleEditWidget(e, widget)} /> : null}
          <div
            className="icon dashboard widgetMove editIconSize"
            onMouseEnter={e => handleMoveWidget(e, widget)}
            onMouseLeave={e => handleUnMoveWidget(e, widget)}
            onTouchStart={e => handleMoveWidget(e, widget)}
            onTouchEnd={e => handleUnMoveWidget(e, widget)}
          />
          <div className="icon dashboard widgetExport editIconSize" onClick={e => handleExportWidget(e, widget)} />
        </div>
      </div>
      {handleWidgetComponent(widget)}
    </>
  );
}
